













import { uploadImage } from '@/api/common'
import { EntityType } from '@/api/project/model'
import { showError } from '@/utils/common'
import { imageTypes, uploadHandle } from '@/utils/upload'
import { defineComponent, inject, reactive } from '@vue/composition-api'
import { Image, Upload } from 'element-ui'
import Editor from '@/components/Editor.vue'
import ImageCheckbox from './components/ImageCheckbox.vue'


export default defineComponent({
    components: {
        [Upload.name]: Upload,
        [Image.name]: Image,
        Editor,
        ImageCheckbox
    },
    setup() {
        const itemObj: any = inject('itemObj')
        itemObj.message_entity.entity_type = EntityType.image
        const imageUploadInfo = reactive({
            loading: false,
            before: (file: File) => {
                return uploadHandle(file, 1)
            },
            request: async (value: any) => {
                imageUploadInfo.loading = true
                try {
                    const { url } = await uploadImage({
                        file: value.file
                    })
                    itemObj.message_entity.link = url
                } catch (error) {
                    showError(error)
                }
                imageUploadInfo.loading = false
            }
        })

        return {
            EntityType,
            imageTypes,
            imageUploadInfo,
            itemObj
        }
    }
})
