




















import { EntityType } from '@/api/project/model'
import { computed, defineComponent, inject, ref } from '@vue/composition-api'
import { Checkbox } from 'element-ui'
import { useSettingStore } from '@/pinia/modules/setting'
import { LocaleType } from '@/interface/common'

export default defineComponent({
    components: {
        [Checkbox.name]: Checkbox
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { emit }) {
        const settingStore = useSettingStore()
        const itemObj: any = inject('itemObj')
        const detailObj: any = inject('detailObj')
        const randomized = computed(() => ![EntityType.ask_opinion,EntityType.onboarding_ask_opinion, EntityType.branch].includes(itemObj.message_entity.entity_type))
        const otherAndNone = computed(() => ![EntityType.ask_opinion,EntityType.onboarding_ask_opinion, EntityType.branch,EntityType.rank].includes(itemObj.message_entity.entity_type))
        const enableVoting = computed(() => [EntityType.ask_opinion, EntityType.branch].includes(itemObj.message_entity.entity_type))
        const enableAI = computed(() => 
            //[EntityType.ask_opinion,EntityType.onboarding_ask_opinion].includes(itemObj.message_entity.entity_type)
            [EntityType.onboarding_ask_opinion].includes(itemObj.message_entity.entity_type)
        )
        const disableAI = computed(() => 
            props.disabled || ![LocaleType.en_US,LocaleType.zh_CN,LocaleType.zh_TW].includes(detailObj.language)
        )

        const handleChange = () => {
            emit('change')
        }
        return {
            itemObj,
            randomized,
            otherAndNone,
            enableVoting,
            enableAI,
            handleChange,
            disableAI
        }
    }
})
