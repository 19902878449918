





























import { defineComponent, reactive, ref } from '@vue/composition-api'
import { Drawer } from 'element-ui'
import ProjectGroupList from './ProjectGroupList.vue'
import ProjectGroupEdit from './ProjectGroupEdit.vue'
export default defineComponent({
    components: {
        [Drawer.name]: Drawer,
        ProjectGroupList,
        ProjectGroupEdit
    },
    setup() {
        const isShowDrawer = ref(false)
        const showType = ref(1)
        const projectGroupListRef = ref()
        const projectGroupEditRef = ref()
        const segmentObj = reactive({
            edit: (item: any) => {
                showType.value = 2
                projectGroupEditRef.value.segmentObj.edit(item)
            },
            add: () => {
                showType.value = 2
                projectGroupEditRef.value.segmentObj.add()
            },
            cancel: () => {
                showType.value = 1
            },
            update: () => {
                showType.value = 1
            }
        })

        return {
            isShowDrawer,
            projectGroupListRef,
            projectGroupEditRef,
            segmentObj,
            showType
        }
    }
})
