






import { defineComponent, inject, reactive, ref } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'

export default defineComponent({
    components: {
        Editor
    },
    setup() {
        const itemObj: any = inject('itemObj')
        return {
            itemObj
        }
    }
})
