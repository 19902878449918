import { EntityType } from './model'
import i18n from '@/locale'

export const allDurationItems = [
    {
        value: 15,
        label: i18n.t('durationItems.15')
    },
    {
        value: 30,
        label: i18n.t('durationItems.30')
    },
    {
        value: 45,
        label: i18n.t('durationItems.45')
    },
    {
        value: 60,
        label: i18n.t('durationItems.60')
    },
    {
        value: 75,
        label: i18n.t('durationItems.75')
    },
    {
        value: 90,
        label: i18n.t('durationItems.90')
    },
    {
        value: 105,
        label: i18n.t('durationItems.105')
    },
    {
        value: 120,
        label: i18n.t('durationItems.120')
    },
    {
        value: 135,
        label: i18n.t('durationItems.135')
    },
    {
        value: 150,
        label: i18n.t('durationItems.150')
    },
    {
        value: 165,
        label: i18n.t('durationItems.165')
    },
    {
        value: 180,
        label: i18n.t('durationItems.180')
    }
]

export const typeDurationOption: any = {
    [EntityType.onboarding_ask_opinion]: {
        value: 15
    },
    [EntityType.onboarding_poll]: {
        value: 15
    },
    [EntityType.poll]: {
        value: 30,
        options: [
            {
                value: 15,
                label: i18n.t('durationItems.15')
            },
            {
                value: 30,
                label: i18n.t('durationItems.30')
            },
            {
                value: 45,
                label: i18n.t('durationItems.45')
            },
            {
                value: 60,
                label: i18n.t('durationItems.60')
            },
            {
                value: 75,
                label: i18n.t('durationItems.75')
            },
            {
                value: 90,
                label: i18n.t('durationItems.90')
            }
        ]
    },
    [EntityType.upload_image]: {
        value: 45,
        options: [
            {
                value: 45,
                label: i18n.t('durationItems.45')
            },
            {
                value: 60,
                label: i18n.t('durationItems.60')
            },
            {
                value: 75,
                label: i18n.t('durationItems.75')
            },
            {
                value: 90,
                label: i18n.t('durationItems.90')
            },
            {
                value: 105,
                label: i18n.t('durationItems.105')
            },
            {
                value: 120,
                label: i18n.t('durationItems.120')
            }
        ]
    },
    [EntityType.upload_video]: {
        value: 90,
        options: [
            {
                value: 45,
                label: i18n.t('durationItems.45')
            },
            {
                value: 60,
                label: i18n.t('durationItems.60')
            },
            {
                value: 75,
                label: i18n.t('durationItems.75')
            },
            {
                value: 90,
                label: i18n.t('durationItems.90')
            },
            {
                value: 105,
                label: i18n.t('durationItems.105')
            },
            {
                value: 120,
                label: i18n.t('durationItems.120')
            }
        ]
    },
    [EntityType.ask_experience]: {
        value: 60,
        options: [
            {
                value: 60,
                label: i18n.t('durationItems.60')
            },
            {
                value: 75,
                label: i18n.t('durationItems.75')
            },
            {
                value: 90,
                label: i18n.t('durationItems.90')
            },
            {
                value: 105,
                label: i18n.t('durationItems.105')
            },
            {
                value: 120,
                label: i18n.t('durationItems.120')
            },
            {
                value: 135,
                label: i18n.t('durationItems.135')
            },
            {
                value: 150,
                label: i18n.t('durationItems.150')
            },
            {
                value: 165,
                label: i18n.t('durationItems.165')
            },
            {
                value: 180,
                label: i18n.t('durationItems.180')
            }
        ]
    },
    [EntityType.rank]: {
        value: 60,
        options: [
            {
                value: 30,
                label: i18n.t('durationItems.30')
            },
            {
                value: 45,
                label: i18n.t('durationItems.45')
            },
            {
                value: 60,
                label: i18n.t('durationItems.60')
            },
            {
                value: 75,
                label: i18n.t('durationItems.75')
            },
            {
                value: 90,
                label: i18n.t('durationItems.90')
            },
            {
                value: 105,
                label: i18n.t('durationItems.105')
            },
            {
                value: 120,
                label: i18n.t('durationItems.120')
            }
        ]
    },
    [EntityType.ask_opinion]: {
        value: 60,
        options: [
            {
                value: 60,
                label: i18n.t('durationItems.60')
            },
            {
                value: 75,
                label: i18n.t('durationItems.75')
            },
            {
                value: 90,
                label: i18n.t('durationItems.90')
            },
            {
                value: 105,
                label: i18n.t('durationItems.105')
            },
            {
                value: 120,
                label: i18n.t('durationItems.120')
            },
            {
                value: 135,
                label: i18n.t('durationItems.135')
            },
            {
                value: 150,
                label: i18n.t('durationItems.150')
            },
            {
                value: 165,
                label: i18n.t('durationItems.165')
            },
            {
                value: 180,
                label: i18n.t('durationItems.180')
            }
        ]
    },
    [EntityType.branch]: {
        value: 120,
        options: [
            {
                value: 90,
                label: i18n.t('durationItems.90')
            },
            {
                value: 105,
                label: i18n.t('durationItems.105')
            },
            {
                value: 120,
                label: i18n.t('durationItems.120')
            },
            {
                value: 135,
                label: i18n.t('durationItems.135')
            },
            {
                value: 150,
                label: i18n.t('durationItems.150')
            },
            {
                value: 165,
                label: i18n.t('durationItems.165')
            },
            {
                value: 180,
                label: i18n.t('durationItems.180')
            }
        ]
    }
}
