










































import { computed, defineComponent, inject, provide, reactive, ref, set, watch } from '@vue/composition-api'
import { useProjectStore } from '@/pinia/modules/project'
import { storeToRefs } from 'pinia'
import { sessionItems } from '@/api/project/entity-type-option'
import { EntityType, EntityTypeOption } from '@/api/project/model'
import EditSpeak from './EditSpeak.vue'
import EditPoll from './EditPoll.vue'
import EditRank from './EditRank.vue'
import EditAskOpinion from './EditAskOpinion.vue'
import EditAskExperience from './EditAskExperience.vue'
import EditImage from './EditImage.vue'
import EditVideo from './EditVideo.vue'
import EditBranch from './EditBranch.vue'
import EditUpload from './EditUpload.vue'
import { updateOrCreateConversationMessageApi } from '@/api/project'
import { showError } from '@/utils/common'
import { verifyMessageEntity } from '@/utils/verify-message-entity'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { typeDurationOption } from '@/api/project/duration-option'
import { entityTypeTemplate, handleNlpSettings } from '@/api/project/entity-type-template'
import _ from 'lodash'
export default defineComponent({
    components: {
        EditSpeak,
        EditPoll,
        EditRank,
        EditAskOpinion,
        EditAskExperience,
        EditImage,
        EditVideo,
        EditBranch,
        EditUpload,
        ErrorMessage
    },
    props: {},
    setup(props, { emit }) {
        const conversationObj: any = inject('conversationObj')
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)
        const errorMessage = ref()
        const submitLoad = ref(false)
        const showDialog = ref(false)
        const optionItems = ref()
        const itemObj: any = reactive({
            id: '',
            guide_id: '',
            project_uuid: '',
            section_id: '',
            sequence: '',
            message_entity: _.cloneDeep(handleNlpSettings(conversationObj.sections))
        })
        provide('itemObj', itemObj)

        const itemWay = ref()
        provide('itemWay', itemWay)

        const initItem = () => {
            const info: any = {
                id: '',
                guide_id: '',
                project_uuid: '',
                section_id: '',
                sequence: '',
                message_entity: _.cloneDeep(handleNlpSettings(conversationObj.sections))
            }
            Object.keys(info).forEach((key) => {
                itemObj[key] = info[key]
            })
            errorMessage.value = ''
        }
        const initMessageEntity = (retainContent: boolean = false) => {
            const entityTypeTemplateCopy = _.cloneDeep(handleNlpSettings(conversationObj.sections))
            Object.keys(entityTypeTemplateCopy).forEach((key: any) => {
                if (key == 'settings') {
                    if (itemObj.message_entity[key].length > 0) {
                        itemObj.message_entity[key][0].options = {
                            none: false,
                            other: false,
                            randomized: false
                        }
                        itemObj.message_entity[key][0].ai_enable = true
                        itemObj.message_entity[key][0].recommend_enable = true
                    } else {
                        itemObj.message_entity[key].push({
                            ai_enable: true,
                            options: {
                                randomized: false,
                                other: false,
                                none: false
                            },
                            recommend_enable: true,
                        })
                    }
                } else if (key == 'content' && retainContent) {

                } else {
                    itemObj.message_entity[key] = entityTypeTemplateCopy[key]
                }
            })
        }

        const initFun = (item: any, type: string) => {
            itemWay.value = type
            Object.keys(itemObj).forEach((key) => {
                set(itemObj, key, item[key])
            })
            if (itemWay.value == 'create') {
                initMessageEntity()
                itemObj.id = undefined
                itemObj.message_entity.test_group_id=0
                itemObj.message_entity.test_group=null
                itemObj.message_entity.entity_type = EntityType.speak
                itemObj.sequence = item.sequence + 1
                console.log(itemObj,'itemObj')
                optionItems.value = sessionItems.filter((v) => v.value != EntityType.branch)
            } else if (itemWay.value == 'edit') {
                if ([EntityType.poll, EntityType.poll_multi, EntityType.picture_poll, EntityType.picture_poll_multi].includes(itemObj.message_entity.entity_type)) {
                    optionItems.value = sessionItems.filter((v) => v.value == EntityType.poll)
                } else if ([EntityType.upload_image, EntityType.upload_video, EntityType.upload_mix].includes(itemObj.message_entity.entity_type)) {
                    optionItems.value = sessionItems.filter((v) => v.value == EntityType.upload_image)
                } else {
                    optionItems.value = sessionItems.filter((v) => v.value == item.message_entity.entity_type)
                }
            }
            showDialog.value = true
        }

        const initBranchFun = (item: any, type: string) => {
            itemWay.value = type
            if (itemWay.value == 'create') {
                initMessageEntity()
                if ([EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(item.message_entity.entity_type)) {
                    const lastSection = conversationObj.sections[conversationObj.sections.length - 1]
                    itemObj.section_id = lastSection.id
                    itemObj.sequence = lastSection.items.length
                } else {
                    itemObj.section_id = item.section_id
                    itemObj.sequence = item.sequence + 1
                }
                
                itemObj.id = undefined
                itemObj.guide_id = item.guide_id
                itemObj.project_uuid = item.project_uuid
                itemObj.message_entity.entity_type = EntityType.branch
                itemObj.message_entity.content = ''
                itemObj.message_entity.link = ''
                itemObj.message_entity.test_group_id=0
                itemObj.message_entity.test_group=null
                itemObj.message_entity.duration = typeDurationOption[EntityType.branch].value
                itemObj.message_entity.project_uuid = item.message_entity.project_uuid
                itemObj.message_entity.parent_id = item.message_entity.id
                itemObj.message_entity.parent_message_entity = item.message_entity
                itemObj.message_entity.options = []
                itemObj.message_entity.settings = []
                itemObj.message_entity.duration = typeDurationOption[EntityType.branch].value
                itemObj.message_entity.project_uuid = item.message_entity.project_uuid
                itemObj.message_entity.parent_id = item.message_entity.id
                itemObj.message_entity.parent_message_entity = item.message_entity
                itemObj.message_entity.options = []
                itemObj.message_entity.settings = []
                itemObj.message_entity.id = item.message_entity.id
                itemObj.message_entity.branchs = [
                    {
                        message_entity_id: item.message_entity.id,
                        name: '--',
                        options: [],
                        content: ''
                    },
                    {
                        message_entity_id: item.message_entity.id,
                        name: '--',
                        options: [],
                        content: ''
                    }
                ]
                itemObj.message_entity.ai_settings= projectInfo.value.ai_settings[EntityType.branch]
            } else if (itemWay.value == 'edit') {
                Object.keys(itemObj).forEach((key) => {
                    set(itemObj, key, item[key])
                })
            }
            optionItems.value = sessionItems.filter((v) => v.value === EntityType.branch)
            showDialog.value = true
        }

        const onChangeOption = (item: EntityTypeOption) => {
            if (itemObj.message_entity.entity_type == item.value) {
                return
            }
            initMessageEntity(true)
            itemObj.message_entity.entity_type = item.value
            itemObj.message_entity.ai_settings= projectInfo.value.ai_settings[item.value]
            if ([EntityType.poll, EntityType.poll_multi, EntityType.rank].includes(item.value)) {
                itemObj.message_entity.options = [{ text: '' }]
            }
            errorMessage.value = ''
        }

        const isOptionActive = (item: EntityTypeOption) => {
            if ([EntityType.poll, EntityType.poll_multi, EntityType.picture_poll, EntityType.picture_poll_multi].includes(itemObj.message_entity.entity_type)) {
                return item.value == EntityType.poll
            }
            if ([EntityType.upload_image, EntityType.upload_video, EntityType.upload_mix].includes(itemObj.message_entity.entity_type)) {
                return item.value == EntityType.upload_image
            }
            return item.value == itemObj.message_entity.entity_type
        }

        const submitSend = async () => {
            errorMessage.value = verifyMessageEntity(itemObj)
            if (errorMessage.value) {
                return
            }
            handleItemObj()
            submitLoad.value = true
            try {
                if(itemObj.message_entity.entity_type=="image"||itemObj.message_entity.entity_type=="video"){
                    itemObj.message_entity.link=""
                }
                // itemObj.message_entity.test_group=null
                // itemObj.message_entity.test_group_id=0
                // itemObj.message_entity.test_group_rounds=[]
                const data = await updateOrCreateConversationMessageApi(itemObj)
                // console.log(data,"datadata")
                // if(data.message_entity.entity_type=="image"||data.message_entity.entity_type=="video"){
                //     data.message_entity.link=''
                // }
                emit('success', itemWay.value, data)
                showDialog.value = false
            } catch (error) {
                showError(error)
            }
            submitLoad.value = false
        }

        const handleItemObj = () => {
            itemObj.message_entity.options?.forEach((v: any, i: number) => {
                v.sequence = i
            })
        }

        return {
            errorMessage,
            EntityType,
            showDialog,
            optionItems,
            itemObj,
            initFun,
            initBranchFun,
            onChangeOption,
            isOptionActive,
            submitSend,
            submitLoad,
            initItem,
            projectInfo
        }
    }
})
