


































































































import { computed, defineComponent, inject, reactive, ref } from "@vue/composition-api";
import { ConversationStatusType, EntityType, ProjectFormType } from "@/api/project/model";
import {
  UPDATE_BASIC_MESSAGE_ENTITY,
  UPDATE_BASIC_MESSAGE_ENTITY_SUCCESS,
  UPDATE_SESSION_MESSAGE_ENTITY,
  UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS,
} from "@/config/event-bus";
import { useEventBus } from "@/hooks/useEventBus";
import { allEntityTypeItems } from "@/api/project/entity-type-option";
import TestGroupStatus from "./components/TestGroupStatus.vue";
export default defineComponent({
  components: {
    TestGroupStatus,
  },
  setup(props, { root, emit }) {
    const { busEmit, busOn } = useEventBus();
    const itemInfo: any = inject("itemInfo");
    const previewList = computed(() =>
      itemInfo.message_entity.options.map((v: any) => v.link)
    );
    const messageInfo = computed(() => {
      const { entity_type, duration } = itemInfo.message_entity;
      const entityTypeOption = allEntityTypeItems.find((v) => v.value == entity_type);
      return {
        name: entityTypeOption?.label,
        icon: entityTypeOption?.icon,
      };
    });

    return {
      ConversationStatusType,
      EntityType,
      ProjectFormType,
      itemInfo,
      messageInfo,
      previewList,
    };
  },
});
