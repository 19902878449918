








import { defineComponent, inject, ref } from '@vue/composition-api'
import Editor from '@/components/Editor.vue'
export default defineComponent({
    components: {
        Editor
    },
    setup(props, { emit }) {
        const itemObj: any = inject('itemObj')
        return {
            itemObj
        }
    }
})
