




































import { deleteSegmentApi } from '@/api/project'
import { computed, defineComponent, reactive, Ref, toRef } from '@vue/composition-api'
import { useProjectStore } from '@/pinia/modules/project'

export default defineComponent({
    setup(props, { root, emit }) {
        const projectStore = useProjectStore()
        const { segment } = projectStore
        const projectUuId = computed(() => root.$route.params.projectUuId)

        const segmentList: Ref<any[]> = toRef(segment, projectUuId.value)
        projectStore.getProjectSegments(projectUuId.value)

        const onDeleteGroup = (item: any, index: number) => {
            root.$msgbox({
                showCancelButton: true,
                message: <string>root.$i18n.t('confirmDeleteMsg')
            })
                .then(async () => {

                    await deleteSegmentApi({
                        uuid: projectUuId.value,
                        id: item.id
                    })
                    segmentList.value.splice(index, 1)
                    root.$message.success(root.$t('deleteSuccess').toString())
                })
                .catch()
        }

        const segmentObj = reactive({
            edit: (item: any) => {
                emit('edit', JSON.parse(JSON.stringify(item)))
            },
            add: () => {
                emit('add')
            }
        })
        return {
            segmentList,
            onDeleteGroup,
            segmentObj
        }
    }
})
