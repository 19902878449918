import { render, staticRenderFns } from "./ProjectLive.vue?vue&type=template&id=c45ff28c&scoped=true&"
import script from "./ProjectLive.vue?vue&type=script&lang=ts&"
export * from "./ProjectLive.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectLive.vue?vue&type=style&index=0&id=c45ff28c&prod&lang=scss&scoped=true&"
import style1 from "./ProjectLive.vue?vue&type=style&index=1&id=c45ff28c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c45ff28c",
  null
  
)

export default component.exports